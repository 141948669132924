
import MoleculePin from "~/components/molecules/project/MoleculePin.vue";

export default {
  name: 'OrganismPins',
  components: {MoleculePin},
  props: {
    pinConfig: {
      type: Object,
      required: true,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      pinsData: [],
      expandedPin: -1,
    };
  },
  computed: {
    engine3d() {
      return this.$engine3d || window.engine3d
    },
    clientManager() {
      return this.engine3d.default();
    }
  },
  watch: {
    pinConfig: {
      deep: true,
      handler: function (newPinConfig) {
        this.pinsData = newPinConfig.pins;
      }
    }
  },
  mounted() {
    if (this.pinConfig) {
      this.pinsData = this.pinConfig.pins;
    }
    this.clientManager.onUpdatePinPosition = (pinState) => {
      requestAnimationFrame(() => this.handleUpdatePinPosition(pinState));
    };

    this.clientManager.onUpdatePinVisibility = (pinState) => {
      requestAnimationFrame(() => this.handleUpdatePinVisibility(pinState));
    };
  },
  methods: {
    handleUpdatePinPosition(pinsState) {
      for (let i = 0; i < this.pinsData.length; i++) {
        const pinState = pinsState[i];
        const componentRef = this.$refs.pinRef[i]?.$el;
        const { x, y } = pinState.position_2d;
        if (componentRef) {
          componentRef.style.transform = `translateX(${x}px) translateY(${y}px)`;
        }
      }
    },
    handleUpdatePinVisibility(pinsState) {
      for (let i = 0; i < this.pinsData.length; i++) {
        const pinState = pinsState[i];
        const componentRef = this.$refs.pinRef[i]?.$el;
        if (componentRef) {
          componentRef.style.display = pinState.visibility
            ? "inline-block"
            : "none";
          if (i === this.expandedPin && !pinState.visibility) {
            this.expandedPin = -1;
          }
        }
      }
    },
    handlePinExpand(index) {
      this.expandedPin = index;
    },
    handlePinClose() {
      this.expandedPin = -1;
    }
  }
}
